import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import { SnackbarProvider } from 'notistack'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from 'styled-components';
import { iniFirebase } from './firebase'
import { UserProvider } from './contexts/User'
import {
  PublicScreen,
  PrivateScreen,
  AuthLogin,
  DashboardScreen,
} from './screens'

import DashboardHome from './components/DashboardHome'
import DashboardGasStations from './components/DashboardGasStations'
import GasStation from './components/GasStation'

type AppProviderProps = {
  children: React.ReactNode
}

const customTheme = createTheme({
  palette: {
    primary: {
      dark: '#0c7739',
      light: '#41bb74',
      main: '#12ab52',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#2979ff',
      dark: '#1c54b2',
      light: '#5393ff',
      contrastText: '#FFFFFF'
    }
  }
});

/**
 * AppUI for non materialui styles
 */
function AppuiProvider ({ children }: AppProviderProps) {
  return <div className="appui">
    { children }
  </div>
}

function FirebaseProvider ({ children }: AppProviderProps) {
  iniFirebase()

  return <>
    { children }
  </>
}

function AppRouter () {
  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<PrivateScreen />}>
        <Route path="dashboard" element={<DashboardScreen />}>
          <Route path="gas-stations">
            <Route path=":gasStationId" element={<GasStation />} />
            <Route path="" element={<DashboardGasStations />} />
          </Route>
          <Route path="" element={<DashboardHome />} />
        </Route>
        <Route path="" element={<Navigate to="/dashboard" />} />
      </Route>
      <Route path="/" element={<PublicScreen />}>
        <Route path="authenticate" element={<AuthLogin />} />
      </Route>
    </Routes>
  </BrowserRouter>
}

const AppProvider = (props: AppProviderProps) => {
  const { children } = props
  return <AppuiProvider>
    <CssBaseline />
    <FirebaseProvider>
      <UserProvider>
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
          <MuiThemeProvider theme={customTheme}>
            <ThemeProvider theme={customTheme}>
              { children }
            </ThemeProvider>
          </MuiThemeProvider>
        </SnackbarProvider>
      </UserProvider>
    </FirebaseProvider>
  </AppuiProvider>
}

const App = () => {
  return <AppProvider>
    <AppRouter />
  </AppProvider>
}

export default App;
