import React, { useState } from 'react'
import { Outlet, Link } from 'react-router-dom'
import styled from 'styled-components'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import HomeIcon from '@mui/icons-material/Home'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import NotificationsIcon from '@mui/icons-material/Notifications'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Theme, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { useUser } from '../hooks/useUser'
import usePageRoutes from '../hooks/usePageRoutes'
// import Logger from '../services/logger'

import imgAppIcon from '../static/images/app-icon.svg'

type StyledTheme = {
  theme: Theme,
}

type HeaderProps = {
  onMenuToggleClick: () => void
}

type MenuProps = {
  onMenuToggleClick?: () => void
}

// const logger = new Logger({
//   filePath: '@/screens/DashboardScreen'
// })

const DashboardContainer = styled(Container)`
  ${({ theme }: StyledTheme) => `
    &.MuiContainer-root {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      max-width: 100%;
      min-height: 100vh;
      padding: 0;
      margin: 0;
    }
  `}
`

const NavBox = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      width: 350px;
      transition: all 250ms linear;
    }
    &.MuiBox-root.menu--docked {
      transform: translateX(-350px);
      width: 0;
    }
    &.MuiBox-root.menu--sm {
      position: fixed;
    }
  `}
`

const MobileNavContainer = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      position: fixed;
      height: 100vh;
      width: 100%;
      transition: all 250ms linear;
      z-index: 5000;
    }
    &.MuiBox-root.mobile-menu--docked {
      transform: translateX(-100%);
    }
    &.MuiBox-root .mobile-menu__overlay {
      opacity: 1;
    }
    &.MuiBox-root.mobile-menu--docked .mobile-menu__overlay {
      opacity: 0;
    }
  `}
`

const MobileNavOverlay = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      height: 100vh;
      width: 100%;
      top: 0;
      left: 0;
      transition: all 250ms linear;
    }
  `}
`

const MobileNavDrawer = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      background-color: #ffffff;
      height: 100vh;
      width: 100%;
      max-width: 350px;
      position: absolute;
      top: 0;
      left: 0;
    }
  `}
`

const ContentBox = styled(Box)`
  ${({ theme }: StyledTheme) => `
    &.MuiBox-root {
      flex-grow: 1;
      background-color: #f5fbfa;
      width: 100%;
    }
  `}
`

const AppNameTypography = styled(Typography)`
  ${({ theme }: StyledTheme) => `
    &.MuiTypography-root {
      font-size: ${theme.typography.h6.fontSize};
      color: ${theme.palette.secondary.main};
      font-weight: bold;
    }
  `}
`

function Header(props: HeaderProps) {
  const { onMenuToggleClick } = props
  const { currentUser } = useUser()

  return <Box p={2}>
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box>
        <IconButton onClick={onMenuToggleClick}>
          <MenuIcon />
        </IconButton>
      </Box>
      <Box>
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton>
            <NotificationsIcon />
          </IconButton>
          <Typography>{currentUser?.displayName}</Typography>
        </Stack>
      </Box>
    </Stack>
  </Box>
}

function Menu(props: MenuProps) {
  const { onMenuToggleClick } = props
  const { logoutUser } = useUser()
  const pageRoutes = usePageRoutes()
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const onMenuClick = () => {
    if (!mdUp && onMenuToggleClick) {
      onMenuToggleClick()
    }
  }

  return <>
    <Box p={2} textAlign="center">
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
        <Stack direction="row" spacing={2} alignItems="center">
          <img src={imgAppIcon} width={32} height={32} alt="Fewlsy Icon" />
          <AppNameTypography>Fewlsy Admin</AppNameTypography>
        </Stack>
        <>
          {!mdUp &&
            <IconButton onClick={onMenuToggleClick}>
              <KeyboardArrowLeftIcon />
            </IconButton>
          }
        </>
      </Stack>
    </Box>
    <nav>
      <List>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={pageRoutes.privatePage.home} onClick={onMenuClick}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to={pageRoutes.privatePage.gasStations} onClick={onMenuClick}>
            <ListItemIcon>
              <LocalGasStationIcon />
            </ListItemIcon>
            <ListItemText primary="Gas Stations" />
          </ListItemButton>
        </ListItem>
      </List>
    </nav>
    <Divider />
    <nav>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={logoutUser}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItemButton>
        </ListItem>
      </List>
    </nav>
  </>
}

export default function DashboardScreen() {
  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  const [ isMenuDocked, setIsMenuDocked ] = useState(mdUp ? false : true)

  const onMenuToggleClick = () => {
    setIsMenuDocked(v => !v)
  }

  return <>
    <DashboardContainer>
      <>
        {!mdUp &&
          <MobileNavContainer className={`${isMenuDocked ? 'mobile-menu--docked' : ''}`}>
            <MobileNavOverlay className="mobile-menu__overlay" />
            <MobileNavDrawer>
              <Menu onMenuToggleClick={onMenuToggleClick} />
            </MobileNavDrawer>
          </MobileNavContainer>
        }
      </>
      <>
        {mdUp &&
          <NavBox className={`${isMenuDocked ? 'menu--docked' : ''}`}>
            <Menu />
          </NavBox>
        }
      </>
      <>
        {!isMenuDocked && <Divider orientation="vertical" flexItem />}
      </>
      <ContentBox className={`${isMenuDocked ? 'menu--docked' : ''}`}>
        <Header onMenuToggleClick={onMenuToggleClick} />
        <Outlet />
      </ContentBox>
    </DashboardContainer>
  </>
}
