import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import LinearProgress from '@mui/material/LinearProgress'

import DashboardContent from './DashboardContent'
import GasStationReview from './GasStationReview'

import useCoreApi from '../hooks/useCoreApi'
import Logger from '../services/logger'
import Error40X from './Error40X'
import * as Types from '../types'

import PH_PROVINCES from '../data/ph-provinces.json'
import PH_GAS_STATION_BRANDS from '../data/ph-gas-stations.json'

export type GetGasStationResponse = {
  data: {
    result?: Types.GasStation
  }
}

const logger = new Logger({
  filePath: '@/components/GasStation'
})

export default function GasStation() {
  const { getCoreApiClient } = useCoreApi()
  const { gasStationId } = useParams()

  const [gasStationNotFound, setGasStationNotFound] = useState<boolean>(false)
  const [gasStation, setGasStation] = useState<Types.GasStation>()

  useEffect(() => {
    let isSubscribed = true

    /**
     * Get gas station info on page load
     */
    const effect = async () => {
      try {
        const coreApi = await getCoreApiClient()
        const getGasStationResponse = await coreApi.get(`/admin/gas-stations/${gasStationId}`) as GetGasStationResponse
  
        if (!isSubscribed) {
          return false
        }
  
        if (getGasStationResponse.data.result) {
          setGasStation({
            ...getGasStationResponse.data.result
          })
        }
      } catch (err: any) {
        if (err?.response?.data?.error?.code) {
          logger.error('Failed to get gas station info', { error: err.response.data.error })

          if (err.response.data.error.code === 'GasStationNotFoundError') {
            setGasStationNotFound(true)
          }
        }
      }
    }

    if (!gasStation) {
      effect()
    }

    return () => {
      isSubscribed = false
    }
  }, [gasStationId, gasStation, getCoreApiClient])

  if (gasStationNotFound) {
    return <Error40X />
  }

  if (!gasStation) {
    return <DashboardContent>
      <LinearProgress />
    </DashboardContent>
  }

  return <DashboardContent>
    <Box p={4}>
      <Box mb={2}>
        <Stack direction="row" spacing={2} alignItems="center">
          <>
            {gasStation.status === 'REJECTED' &&
              <Tooltip title="This gas station location has been rejected." arrow>
                <Chip label="Rejected" color="error" size="small" />
              </Tooltip>
            }
          </>
          <>
            {gasStation.status === 'PENDING_REVIEW' &&
              <Tooltip title="This gas station location is still in review." arrow>
                <Chip label="Pending Review" color="warning" size="small" />
              </Tooltip>
            }
          </>
          <>
            {gasStation.status === 'ACTIVE' &&
              <Chip label="Active" color="info" size="small" />
            }
          </>
          <Typography variant="h6">{gasStation.label}</Typography>
          <Typography variant="body1">{gasStation.businessName}</Typography>
          <Typography variant="body1">{PH_GAS_STATION_BRANDS.filter(brands => brands.code === gasStation.brand)[0].name}</Typography>
        </Stack>
      </Box>
      <>
        {gasStation.status === 'PENDING_REVIEW' &&
          <Box mb={2} maxWidth={450}>
            <Alert severity="warning">
              <Box mb={1}>
                <Typography variant="body2">This Gas Station Location is still pending reviewed.</Typography>
              </Box>
              <Box>
                <Typography variant="body2">{`Last updated: ${new Date(gasStation.updatedAt).toDateString()}`}</Typography>
              </Box>
              <>
                {gasStation?.status === 'PENDING_REVIEW' &&
                  <Box mt={2}>
                    <GasStationReview businessAccountId={gasStation.businessAccountId} gasStationId={gasStation.uuid} />
                  </Box>
                }
              </>
            </Alert>
          </Box>
        }
      </>

      <Stack direction="row" spacing={2}>
        <Box component={Paper} width={450} p={2}>
          <Box mb={2}>
            <Box mb={2}>
              <Typography color="primary" variant="h6" component="h2">Address Information</Typography>
            </Box>
            <Box mb={2}>
              <Typography color="GrayText" variant="body2">Street</Typography>
              <Typography variant="body1">{gasStation.addressStreet}</Typography>
            </Box>
            <Box mb={2}>
              <Typography color="GrayText" variant="body2">Barangay</Typography>
              <Typography variant="body1">{gasStation.addressBarangay}</Typography>
            </Box>
            <Box mb={2}>
              <Typography color="GrayText" variant="body2">City</Typography>
              <Typography variant="body1">{gasStation.addressCity}</Typography>
            </Box>
            <Box mb={2}>
              <Typography color="GrayText" variant="body2">Zip Code</Typography>
              <Typography variant="body1">{gasStation.addressPostalCode}</Typography>
            </Box>
            <Box mb={2}>
              <Typography color="GrayText" variant="body2">Province</Typography>
              <Typography variant="body1">{PH_PROVINCES.filter(province => province.code === gasStation.addressProvince)[0].name}</Typography>
            </Box>
            <Box>
              <Typography color="GrayText" variant="body2">Country</Typography>
              <Typography variant="body1">{gasStation.addressCountry}</Typography>
            </Box>
          </Box>
          <Box mb={1}>
            <Typography color="primary" variant="body1" component="h2">Map GPS Coordinates</Typography>
          </Box>
          <Box mb={2}>
            <Stack direction="row" spacing={2}>
              <Box>
                <Typography color="GrayText" variant="body2">Latitude</Typography>
                <Typography variant="body1">{gasStation.addressLatitude}</Typography>
              </Box>
              <Box>
                <Typography color="GrayText" variant="body2">Longitude</Typography>
                <Typography variant="body1">{gasStation.addressLongitude}</Typography>
              </Box>
            </Stack>
          </Box>
          <Box>
            <Button variant="outlined" color="secondary" component="a" href={`https://maps.google.com/?q=${gasStation.addressLatitude},${gasStation.addressLongitude}`} target="_blank" rel="noreferrer">Preview Map</Button>
          </Box>
        </Box>
        <Box component={Paper} width={450} p={2}>
          <Box mb={2}>
            <Typography color="primary" variant="h6" component="h2">Business Information</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Business Name</Typography>
            <Typography variant="body1">{gasStation.businessName}</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Brand</Typography>
            <Typography variant="body1">{`${PH_GAS_STATION_BRANDS.filter(brands => brands.code === gasStation.brand)[0].name} (${gasStation.brand})`}</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Label</Typography>
            <Typography variant="body1">{gasStation.label}</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Primary Contact Email</Typography>
            <Typography variant="body1">{gasStation.primaryContactEmail}</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Primary Contact Phone</Typography>
            <Typography variant="body1">{gasStation.primaryContactPhoneNumber}</Typography>
          </Box>
          <Box mb={2}>
            <Typography color="GrayText" variant="body2">Is the location a Dealer Operated Franchise?</Typography>
            <Typography variant="body1">{gasStation.isDealerOperatedFranchise ? 'YES' : 'NO'}</Typography>
          </Box>
          <>
            {gasStation.isDealerOperatedFranchise &&
              <Box>
                <Typography color="GrayText" variant="body2">Franchise Expiration</Typography>
                <>
                  {(gasStation.franchiseExpirationMonth !== undefined && gasStation.franchiseExpirationYear !== undefined) &&
                    <Typography variant="body1">{`${['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'][gasStation.franchiseExpirationMonth]} ${gasStation.franchiseExpirationYear}`}</Typography>
                  }
                </>
              </Box>
            }
          </>
        </Box>
      </Stack>
    </Box>
  </DashboardContent>
}
